import * as Sentry from "@sentry/astro";

Sentry.init({
  dsn: "https://12554d9a5d27b7a2eb25efb2e79c9495@o371812.ingest.us.sentry.io/4507018081730560",
  debug: false,
  environment: "staging",
  release: import.meta.env.PUBLIC_VERCEL_GIT_COMMIT_SHA,
  tracesSampleRate: 1,
  integrations: [new Sentry.BrowserTracing(), new Sentry.Replay()],
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1,
});